import React from "react"
import Footer from "../Footer/Footer"
import { useNavigate } from 'react-router-dom';
import './styles.css';

export default function DeliveryPage() {
    const navigate = useNavigate();

    const handleClick = () => {
        
        navigate(`/`);
    };
    return (
        
        <div>
            <div className="container">
            <div className="back_about_cont">
            <p className="back-about" style={{paddingBottom:'60px', paddingTop:'60px'}} onClick={()=>handleClick()} > Назад</p>
            </div>
            <div className="about-us-cont">
                <h3 style={{fontWeight:'600', paddingBottom:'40px', color:'#212395', fontSize:'35px'}}>Доставка и оплата</h3>
                <h4>Мы осуществляем доставку заказов на всей территории Российской Федерации.

Сроки доставки зависят от наличия товаров на складе. В случае, если все выбранные товары имеются в наличии на момент оформления заказа, мы доставим его в кратчайшие сроки, которые будут уточнены у менеджера, исходя из удаленности Вашего региона.</h4>
                <h3 style={{paddingTop:'10px'}}><b>Доставка транспортными компаниями</b></h3>
                <h4 style={{fontSize:'15px', paddingTop:'20px'}}>Мы осуществляем доставку транспортными и курьерскими компаниями только на территории Российской Федерации.

Чтобы ваш заказ был отправлен в тот же день, необходимо оформить его до 16:00 в будний день или до 13:00 в выходные дни. Если вы оформили заказ после указанных временных рамок, то он будет отправлен на следующий день.

Минимальная сумма заказа составляет 3 000 рублей и может включать как один вид товара, так и разные наименования из нашего ассортимента.

Мы предлагаем два варианта доставки заказа: самовывоз из пункта выдачи транспортной компании или доставка курьером по указанному адресу получателя. Для выбора удобного для вас варианта, пожалуйста, ознакомьтесь с информацией о наличии пунктов выдачи и их режимами работы на сайтах транспортных компаний.

На данный момент мы работаем с несколькими транспортными компаниями, в том числе:</h4>
                <h3 className="all_deleivery">- Кит</h3>
                <h3 className="all_deleivery">- Карго</h3>
                <h3 className="all_deleivery">- СДЭК</h3>
                <h3 className="all_deleivery">- Деловые Линии</h3>
                <h3 className="all_deleivery">- ПЭК</h3>
                <h3 className="all_deleivery">- DPD</h3>
                <h3 className="all_deleivery">- BOXBERRY</h3>
                <h4 className="all_deleivery">Мы оставляем за собой право выбора транспортной компании для доставки товара.</h4>
               <h4 style={{paddingTop:'20px'}}><b>Стоимость доставки</b></h4>
        <h4 className="all_deleivery">
Стоимость доставки заказов зависит от выбранной ТК, размера заказа и отдаленности региона.

Стоимость доставки может варьироваться в диапазоне от 400 до 1200 рублей. Окончательная стоимость будет согласована с менеджером при оформлении заказа.

Однако, если сумма заказа превышает 30 000 рублей, то доставка осуществляется бесплатно (за наш счет).</h4>
               <h4 style={{paddingTop:'30px'}}><b>Предлагаются следующие способы оплаты:</b></h4>
        <h4 className="all_deleivery">
        - Картой - Visa, Mastercard, Maestro, Мир..</h4>
        <h4 className="all_deleivery">
        - Яндекс Деньги.</h4>
        <h4 className="all_deleivery">
        - ЮMoney кошелёк. При оплате заказа на сумму от 60000 рублей через ЮMoney кошелёк, необходимо обязательно иметь статус "Профессиональный". Если у вас статус ниже, пожалуйста выберите другой способ оплаты.</h4>
            </div>
            </div>
            <Footer />
        </div>
    )
};


